.animate {
  animation: fly 6s linear infinite;
  will-change: transform;
}

@keyframes fly {
  0%, 100% {
    transform: translate(0, 0) rotate(var(--rotate-start)) scale(var(--scale));
  }
  25% {
    --tw-rotate: calc(var(--tw-rotate-start) + 3deg * var(--delta));
    transform: translate(0, -10%) rotate(calc(var(--rotate-start) + 3deg * var(--delta))) scale(var(--scale));
  }
  75% {
    --tw-rotate: calc(var(--tw-rotate-start) - 3deg * var(--delta));
    transform: translate(0, 10%) rotate(calc(var(--rotate-start) - 3deg * var(--delta))) scale(var(--scale));
  }
}
