.bar {
  --start-x: 0;
  --start-y: 0;
  --end-x: 0;
  --end-y: 0;
  --duration: 4s;
  animation-name: scrolling;
  animation-duration: var(--duration);
  animation-iteration-count: infinite;
  will-change: transform;
}

@keyframes scrolling {
  0%, 100% {
    transform: translate(var(--start-x), var(--start-y));
  }
  50% {
    transform: translate(var(--end-x), var(--end-y));
  }
}
